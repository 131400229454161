// 我的提交详情
<template>
  <div class="my_submit_detail">
    <i-nav-bar :iTitle="title"></i-nav-bar>
    <div class="main">
      <div class="main_num">提交时间：{{ detail.c_time }}</div>

      <div class="detail_top">
        <div class="img_box detail_l">
          <div class="l_box box_border">
            <img
              class="star_img"
              :src="
                judgeImgEffective(detail.img_url)
                  ? detail.img_url
                  : require('./../../assets/images/ido/pic_star_default.png')
              "
              alt=""
            />
          </div>
          <div class="star_txt txt_c mgt10">{{ detail.star_name }}</div>
        </div>
        <div class="detail_c">
          <img
            class="c_img"
            src="./../../assets/images/mySubmit/pic_wavy.png"
            alt=""
          />
        </div>
        <div class="img_box detail_r">
          <div class="r_box">
            <img
              class="scene_img"
              :src="
                detail.scene_img ||
                require('./../../assets/images/scene/pic_scene_default.png')
              "
              alt=""
            />
          </div>
          <div class="star_txt mgt10">
            {{ detail.scene_name }}
          </div>
          <div class="star_txt">
            {{ detail.scene_type == 1 ? "（送自己）" : "（送亲友）" }}
          </div>
        </div>
      </div>

      <div class="text_box mgt20">
        <img
          class="body_img"
          src="./../../assets/images/mySubmit/back_ipt.png"
          alt=""
        />
        <div class="txt_content">
          {{ fontText(detail.con, 100) }}
        </div>

        <div class="give_box">
          <img
            v-if="detail.is_yes"
            class="give_img"
            src="./../../assets/images/icon_give.png"
            alt=""
          />
          <img
            v-else
            class="give_img"
            src="./../../assets/images/icon_cancel_give.png"
            alt=""
          />
          <span class="give_num">{{ detail.yes_num || 0 }}</span>
        </div>
      </div>
    </div>

    <!-- 底部提交按钮 -->
    <div class="footer">
      <van-button class="footer_btn btn1" color="#111111" round @click="goBack">
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_left.png"
          alt=""
        />
      </van-button>
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
import { fontText, judgeImgEffective } from "@/utils/utils";
import { infoDetail } from "@/request/api";

export default {
  name: "MySubmit",
  components: {
    iNavBar,
  },
  data() {
    return {
      fontText,
      judgeImgEffective,
      title: "我的提交",
      detail: {
        con: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log();
      if (this.$route.query?.id) {
        let params = {
          info_id: this.$route.query.id,
        };
        this.getMsgDetail(params);
      }
    },
    // 获取信息详情
    async getMsgDetail(params) {
      let data = await infoDetail(params);
      if (data.rs_code == 1000) {
        this.detail = data?.details || {};
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.my_submit_detail {
  width: 100%;
  min-height: 100%;
  background: #5257cf;
  .main_num {
    height: 20px;
    line-height: 20px;
    background: #111111;
    font-size: 12px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #4bfff2;
    text-align: center;
  }
  .detail_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px !important;
    width: 92%;
    height: 168px;
    margin: 0 auto;
    // background-color: yellow;
    .img_box {
      width: 42%;
      height: 100%;
      background: url("./../../assets/images/mySubmit/back_decoration.png")
        no-repeat 100% 100%;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .l_box {
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background: #6f87ff;
        .star_img {
          position: absolute;
          width: 55px;
          height: 55px;
          border-radius: 50%;
          object-fit: contain;
          bottom: 0;
        }
      }

      .r_box {
        position: relative;
        width: 61px;
        height: 61px;
        border: 1px solid #6f87ff;
        box-sizing: border-box;
        .scene_img {
          position: absolute;
          width: 41px;
          height: 41px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .box_border {
        border: 2px solid #4bfff2;
      }
      .star_txt {
        font-size: 14px;
        width: 90px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ffffff;
        text-align: center;
      }
    }

    .detail_c {
      width: 11%;
      height: 10%;
      .c_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .text_box {
    position: relative;
    width: 92%;
    margin: 0 auto;
    min-height: 120px;
    .body_img {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .txt_content {
      position: absolute;
      left: 0;
      box-sizing: border-box;
      text-align: justify;
      width: 100%;
      height: 100%;
      padding: 10px;
      min-height: 120px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
    }
    .give_box {
      position: absolute;
      right: 35px;
      box-sizing: border-box;
      bottom: -15px;
      min-width: 60px;
      height: 30px;
      background: #5257cf;
      border: 0.5px solid #4bfff2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      .give_img {
        // position: absolute;
        width: 20px;
        height: 20px;
        // left: 10px;
        // top: 0;
        // bottom: 0;
        // margin: auto;
      }
      .give_num {
        // position: absolute;
        font-size: 14px;
        color: #4bfff2;
        margin-left: 3px;
        // top: 6px;
        // left: 33px;
        // line-height: 20px;
      }
    }
  }
}

.footer {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 10;

  .footer_btn {
    width: 100%;
    height: 40px;

    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
      line-height: 20px;
    }
  }
  .left_img {
    width: 22px;
    height: 10px;
    object-fit: contain;
  }
  .btn1 {
    position: relative;
    width: 82px;
    background: #111111;
    .van-button__text {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
      line-height: 20px;
    }
  }
}
</style>